import React, { useState } from "react";
import { Link } from "gatsby";
import Collapse from "react-bootstrap/Collapse";
import { StaticImage } from "gatsby-plugin-image";

//components
import Button from "../../components/custom-widgets/button";
// Styles
import styles from "./savings-overview.module.scss";

// Helpers
import useScrollOffset from "../../helpers/useScrollOffset";

const MinorSavingsOverview = () => {
  const [collapseClass1, setCollapseClass1] = useState(false);
  const [collapseClass2, setCollapseClass2] = useState(false);
  const [collapseClass3, setCollapseClass3] = useState(false);

  const LocationsBtnData = {
    id: "cta-apply-at-branch",
    containerClass: "mb-4 mb-md-0",
    type: "link",
    class: "btn-primary w-100 w-sm-auto w-md-100",
    text: "Open at Branch",
    url: "/locations",
    showIcon: false
  };

  // use the scroll offset (based upon the height of the sticky header) to pad the scrolling correctly.
  const scrollOffset = useScrollOffset();

  return (
    <section id="minor-savings-overview-section" className="container" style={{ scrollMarginTop: scrollOffset }}>
      <h2 className="text-center">Kid and Teen Accounts</h2>
      {/* HIDDEN XS & SM */}
      <div className="d-none d-md-block">
        <div className="row">
          <div className="col">
            <span className="sr-only">Compare minor savings account details table</span>

            {/* COMPARE SAVINGS/MONEY MARKEY/CDS TABLE */}
            <table
              id="savings-overview-table"
              className={`table table-bordered table-fixed cols-3 ${styles.SavingsOverview}`}
            >
              <thead>
                <tr className="text-center">
                  <th title="Savings" className={styles.ribbonBox}>
                    <div className={styles.ribbon}>
                      <StaticImage
                        src="../../images/wafd-walt-heart.png"
                        alt="WaFd Bank Walt Heart"
                        placeholder="blurred"
                        className=""
                        quality="100"
                        width={100}
                        style={{ top: "10px", left: "10px" }}
                      />
                      <span>WALT's CHOICE</span>
                    </div>
                    <h2>
                      <strong>Savings</strong>
                    </h2>
                  </th>
                  <th title="Money Market">
                    <h2>
                      <strong>Money Market</strong>
                    </h2>
                  </th>
                  <th title="Gold Money Market">
                    <h2>
                      <strong>Gold</strong>
                      <br />
                      Money Market
                    </h2>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Button {...LocationsBtnData} id="locations-savings-btn" />
                  </td>
                  <td>
                    <Button {...LocationsBtnData} id="locations-mm-btn" />
                  </td>
                  <td>
                    <Button {...LocationsBtnData} id="locations-gold-mm-btn" />
                  </td>
                </tr>
                <tr>
                  <td className="text-center">
                    <p>
                      <strong>$10 initial deposit</strong>
                    </p>
                  </td>
                  <td className="text-center">
                    <p>
                      <strong>Only $25 initial deposit</strong>
                    </p>
                  </td>
                  <td className="text-center">
                    <p>
                      <strong>Only $25 initial deposit</strong>
                    </p>
                  </td>
                </tr>

                <tr>
                  <td className="text-center">
                    <p>
                      Minimum to earn interest is $10.
                      <br />
                      Earns 1% Annual Percentage Yield.
                      <br />
                      This is a variable rate account.
                      <br />
                      <Link to="/personal-banking/interest-rates" id="savings-rate-link">
                        See interest rates/APY
                      </Link>
                    </p>
                  </td>

                  <td className="text-center">
                    <p>
                      Minimum to earn interest is $1,000.
                      <br />
                      this is a variable rate account.
                      <br />
                      <Link to="/personal-banking/interest-rates" id="mm-rates-link">
                        See interest rates/APY
                      </Link>
                    </p>
                  </td>
                  <td className="text-center">
                    <p>
                      Minimum to earn interest is $1,000.
                      <br />
                      Earn interest at a higher rate with a Gold Money Market account. This is a variable rate account.
                      <br />
                      <Link to="/personal-banking/interest-rates" id="gold-mm-rates-link">
                        See interest rates/APY
                      </Link>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="text-center">
                    <p>
                      <strong>No monthly fee</strong>
                    </p>
                  </td>

                  <td className="text-center">
                    <p>$10 monthly fee waived on balances above $1,000</p>
                  </td>
                  <td className="text-center">
                    <p>$10 monthly fee waived on balances above $1,000</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* VISIBLE XS & SM */}
      <div className="mb-3 d-block d-md-none">
        {/* SAVINGS */}
        <article className="mb-3 border-bottom">
          <h2>
            <strong>Savings</strong>
          </h2>
          <div id="savings-fee" className={`${styles.mobileDetailsItem} font-weight-bold`}>
            No monthly fee
          </div>
          <div
            id="savings-toggle"
            onClick={() => {
              setCollapseClass1(!collapseClass1);
            }}
            className={`${styles.mobileDetails} ${
              collapseClass1 ? styles.mobileDetailsOpen : styles.mobileDetailsClosed
            }`}
            aria-label="Show/Hide Details"
            aria-controls="cd-special-details"
            aria-expanded={collapseClass1}
            role="button"
          ></div>
          {/* "Show/Hide Details" via CSS ::after content */}
          <Collapse in={collapseClass1}>
            <div id="savings-details">
              <div className={`${styles.mobileDetailsItem} `}>
                <strong>$10 initial deposit</strong>
              </div>
              <div className={styles.mobileDetailsItem}>
                Minimum to earn interest is $10.
                <br />
                Earns 1% Annual Percentage Yield.
                <br />
                This is a variable rate account.
                <br />
                <Link to="/personal-banking/interest-rates">See interest rates/APY</Link>
              </div>
            </div>
          </Collapse>
          <Button {...LocationsBtnData} id="cta-apply-at-branch-1" />
        </article>
        {/* Money Market */}
        <article className="mb-3 border-bottom">
          <h2>Money Market</h2>
          <div className={`${styles.mobileDetailsItem}`} id="money-market-fee">
            <div className="font-weight-bold">$10 monthly fee waived on balances above $1,000</div>
          </div>
          <div
            id="money-market-toggle"
            onClick={() => {
              setCollapseClass2(!collapseClass2);
            }}
            className={`${styles.mobileDetails} ${
              collapseClass2 ? styles.mobileDetailsOpen : styles.mobileDetailsClosed
            }`}
            aria-label="Show/Hide Details"
            aria-controls="money-market-details"
            aria-expanded={collapseClass2}
            role="button"
          ></div>
          {/* "Show/Hide Details" via CSS ::after content */}
          <Collapse in={collapseClass2}>
            <div id="money-market-details">
              <div className={`${styles.mobileDetailsItem} font-weight-bold`}>Only $25 initial deposit</div>
              <div className={`${styles.mobileDetailsItem}`}>
                Minimum to earn interest is $1,000.
                <br />
                this is a variable rate account.
                <br />
                <Link to="/personal-banking/interest-rates">See interest rates/APY</Link>
              </div>
            </div>
          </Collapse>
          <Button {...LocationsBtnData} id="cta-apply-at-branch-2" />
        </article>
        {/* Gold Money Market*/}
        <article className="mb-3 border-bottom">
          <h2>
            <strong>Gold</strong> Money Market
          </h2>
          <div className={`${styles.mobileDetailsItem}`} id="gold-money-market-fee">
            <div className="font-weight-bold">$10 monthly fee waived on balances above $1,000</div>
          </div>
          <div
            id="gold-money-market-toggle"
            onClick={() => {
              setCollapseClass3(!collapseClass3);
            }}
            className={`${styles.mobileDetails} ${
              collapseClass3 ? styles.mobileDetailsOpen : styles.mobileDetailsClosed
            }`}
            aria-label="Show/Hide Details"
            aria-controls="gold-money-market-details"
            aria-expanded={collapseClass3}
            role="button"
          ></div>
          {/* "Show/Hide Details" via CSS ::after content */}
          <Collapse in={collapseClass3}>
            <div id="gold-money-market-details">
              <div className={`${styles.mobileDetailsItem} font-weight-bold`}>Only $25 initial deposit</div>
              <div className={`${styles.mobileDetailsItem}`}>
                Minimum to earn interest is $1,000.
                <br />
                Earn interest at a higher rate with a Gold Money Market account.
                <br />
                This is a variable rate account.
                <br />
                <Link to="/personal-banking/interest-rates">See interest rates/APY</Link>
              </div>
            </div>
          </Collapse>
          <Button {...LocationsBtnData} id="cta-apply-at-branch-3" />
        </article>
      </div>
    </section>
  );
};
export default MinorSavingsOverview;
