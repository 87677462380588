import { useState, useEffect } from "react";

const BUFFER_HEIGHT_IN_PX = 12; // 12px to account for the height of the shadow below the header
const DEFAULT_OFFSET_HEIGHT = "120px"; // desktop header height of 108px + 12px buffer height = 120px

// Custom Hook
function useScrollOffset(bufferHeight) {
  const [offset, setOffset] = useState(null);
  const buffer = bufferHeight || BUFFER_HEIGHT_IN_PX;

  useEffect(() => {
    function handleResize() {
      const header = document.getElementById("primary-nav-header");
      const height = Math.floor(header.offsetHeight) + buffer;
      const offsetHeight = height + "px";
      //console.log("handleResize() offset: " + offsetHeight);
      setOffset(offsetHeight);
    }

    // Add event listener
    if (window !== undefined) {
      window.addEventListener("resize", handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    } else {
      setOffset(DEFAULT_OFFSET_HEIGHT);
      return null;
    }
  }, []); // empty array ensures that effect is only run on mount

  return offset;
}

export default useScrollOffset;
